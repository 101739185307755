var PngIcons = {
    gradient: '/images/gradient.png',
    divcontainerinner: '/images/divcontainerinner.png',
    brandicon: '/images/brandIcon.png',
    SignUpStandAlone: '/images/SignUpStandAlone.png',
    SignUpCloudWaitress: '/images/SignUpCloudWaitress.png',
    createReward: '/images/createReward.png',
    BC: '/images/BC.png',
    VipClub: '/images/VipClub.png',
    loyalityTiers: '/images/loyalityTiers.png',
    ceateFlow: '/images/ceateFlow.png',
    CloudWaitressCreateflow: '/images/CloudWaitressCreateflow.png',
    SharedLoytality: '/images/SharedLoytality.png',
    redemption: '/images/redemption.png',
    iwithCloud: '/images/iwithCloud.png',
    ghl: '/images/ghl.png',
    manyChatt: '/images/manyChatt.png',
    BrandsetupA: "/images/BrandsetupA.png",
    BrandsetupB: "/images/BrandsetupB.png",
    BrandsetupC: "/images/BrandsetupC.png",
    BrandsetupD: "/images/BrandsetupD.png",
    BrandsetupE: "/images/BrandsetupE.png",
    BrandsetupF: "/images/BrandsetupF.png",
    BrandsetupG: "/images/BrandsetupG.png",
    businessconnectorA: "/images/businessconnectorA.png",
    businessconnectorB: "/images/businessconnectorB.png",
    businessconnectorC: "/images/businessconnectorC.png",
    businessconnectorD: "/images/businessconnectorD.png",
     CloudwaitressA:  "/images/cloudpic/CloudwaitressA.png",
 CloudwaitressB:  "/images/cloudpic/CloudwaitressB.png",
 CloudwaitressC:  "/images/cloudpic/CloudwaitressC.png",
CloudwaitressD:  "/images/cloudpic/CloudwaitressD.png",
 CloudwaitressE:  "/images/cloudpic/CloudwaitressE.png",
 CloudwaitressF:  "/images/cloudpic/CloudwaitressF.png",
 CloudwaitressG:  "/images/cloudpic/CloudwaitressG.png",
 CloudwaitressH:  "/images/cloudpic/CloudwaitressH.png",
 CloudwaitressI:  "/images/cloudpic/CloudwaitressI.png",
  CreateflowA:  "/images/flowpic/CreateflowA.png",
 CreateflowB:  "/images/flowpic/CreateflowB.png",
 CreateflowC:  "/images/flowpic/CreateflowC.png",
 CreateflowD:  "/images/flowpic/CreateflowD.png",
 CreateflowE:  "/images/flowpic/CreateflowE.png",
 CreateflowF:  "/images/flowpic/CreateflowF.png",
 CreateflowG:  "/images/flowpic/CreateflowG.png",
 CreateflowH:  "/images/flowpic/CreateflowH.png",
 CreateflowI:  "/images/flowpic/CreateflowI.png",
 CreateflowJ:  "/images/flowpic/CreateflowJ.png",
 CreateflowK:  "/images/flowpic/CreateflowK.png",
 CreateflowL:  "/images/flowpic/CreateflowL.png",
 CreateflowM:  "/images/flowpic/CreateflowM.png",
 CreateflowN:  "/images/flowpic/CreateflowN.png",
 CreateflowO:  "/images/flowpic/CreateflowO.png",
 CreateflowP:  "/images/flowpic/CreateflowP.png",
 CreateflowQ:  "/images/flowpic/CreateflowQ.png",
 CreateflowR:  "/images/flowpic/CreateflowR.png",
 CreateflowS:  "/images/flowpic/CreateflowS.png",
 CreateflowT:  "/images/flowpic/CreateflowT.png",

 createrewardA:  "/images/createrewardA.png",
createrewardB:  "/images/createrewardB.png",
createrewardC:  "/images/createrewardC.png",
createrewardD:  "/images/createrewardD.png",

ghlintegartionA: "/images/ghlintegartionA.png",
ghlintegartionB: "/images/ghlintegartionB.png",
ghlintegartionC: "/images/ghlintegartionC.png",
ghlintegartionD: "/images/ghlintegartionD.png",


 ManyChat :"/images/ManyChat.png",
 ManyChatA: "/images/ManyChatA.png",
 ManyChatB: "/images/ManyChatB.png",
 ManyChatC: "/images/ManyChatC.png",
 ManyChatD: "/images/ManyChatD.png",
 ManyChatE: "/images/ManyChatE.png",

 LoyalitytiersA:  "/images/LoyalitytiersA.png",
 LoyalitytiersB:  "/images/LoyalitytiersB.png",
 LoyalitytiersC:  "/images/LoyalitytiersC.png",
 LoyalitytiersD:  "/images/LoyalitytiersD.png",

 RedemptionAA: "/images/RedemptionAA.png",
 RedemptionA: "/images/RedemptionA.png",
 RedemptionB: "/images/RedemptionB.png",
 RedemptionC: "/images/RedemptionC.png",
 RedemptionD: "/images/RedemptionD.png",
 RedemptionE: "/images/RedemptionE.png",
 RedemptionF: "/images/RedemptionF.png",

sharedloyalityA:  "/images/sharedloyalityA.png",
sharedloyalityB:  "/images/sharedloyalityB.png",
sharedloyalityC:  "/images/sharedloyalityC.png",
sharedloyalityD:  "/images/sharedloyalityD.png",
sharedloyalityE:  "/images/sharedloyalityE.png",
sharedloyalityF:  "/images/sharedloyalityF.png",

 SignupClouadwaitressA:  "/images/signupcloudpic/SignupClouadwaitressA.png",
 SignupClouadwaitressB:  "/images/signupcloudpic/SignupClouadwaitressB.png",
 SignupClouadwaitressC:  "/images/signupcloudpic/SignupClouadwaitressC.png",
 SignupClouadwaitressD:  "/images/signupcloudpic/SignupClouadwaitressD.png",
 SignupClouadwaitressE:  "/images/signupcloudpic/SignupClouadwaitressE.png",
 SignupClouadwaitressF:  "/images/signupcloudpic/SignupClouadwaitressF.png",
 SignupClouadwaitressG:  "/images/signupcloudpic/SignupClouadwaitressG.png",
 SignupClouadwaitressH:  "/images/signupcloudpic/SignupClouadwaitressH.png",
 SignupClouadwaitressI:  "/images/signupcloudpic/SignupClouadwaitressI.png",
 SignupClouadwaitressJ:  "/images/signupcloudpic/SignupClouadwaitressJ.png",
 SignupClouadwaitressK:  "/images/signupcloudpic/SignupClouadwaitressK.png",
 SignupClouadwaitressL:  "/images/signupcloudpic/SignupClouadwaitressL.png",
 SignupClouadwaitressM:  "/images/signupcloudpic/SignupClouadwaitressM.png",
 SignupClouadwaitressN:  "/images/signupcloudpic/SignupClouadwaitressN.png",
 SignupClouadwaitressO:  "/images/signupcloudpic/SignupClouadwaitressO.png",
 SignupClouadwaitressP:  "/images/signupcloudpic/SignupClouadwaitressP.png",
 SignupClouadwaitressQ:  "/images/signupcloudpic/SignupClouadwaitressQ.png",
 SignupClouadwaitressR:  "/images/signupcloudpic/SignupClouadwaitressR.png",
 SignupClouadwaitressS:  "/images/signupcloudpic/SignupClouadwaitressS.png",


  standaloneA:  "/images/standaloneA.png",
 standaloneB:  "/images/standaloneB.png",
 standaloneC:  "/images/standaloneC.png",
 standaloneD:  "/images/standaloneD.png",
 standaloneE:  "/images/standaloneE.png",
 standaloneF:  "/images/standaloneF.png",
 standaloneG:  "/images/standaloneG.png",
 standaloneH:  "/images/standaloneH.png",
 standaloneI:  "/images/standaloneI.png",
 standaloneJ:  "/images/standaloneJ.png",
 standaloneK:  "/images/standaloneK.png",
 standaloneL:  "/images/standaloneL.png",
 standaloneM:  "/images/standaloneM.png",
 standaloneN:  "/images/standaloneN.png",
 standaloneP:  "/images/standaloneP.png",

  vipclubA:  "/images/vipclubA.png",
  vipclubB:  "/images/vipclubB.png",
  vipclubC:  "/images/vipclubC.png",
  vipclubD:  "/images/vipclubD.png",
  vipclubE:  "/images/vipclubE.png",
  vipclubF:  "/images/vipclubF.png",
  vipclubG:  "/images/vipclubG.png",
  vipclubH:  "/images/vipclubH.png",
  vipclubI:  "/images/vipclubI.png",
  vipclubJ:  "/images/vipclubJ.png",
  vipclubK:  "/images/vipclubK.png",
  vipclubL:  "/images/vipclubL.png",
  vipclubM:  "/images/vipclubM.png",



 StandaloneA:  "/images/pic/StandaloneA.png",
 StandaloneB:  "/images/pic/StandaloneB.png",
 StandaloneC:  "/images/pic/StandaloneC.png",
 StandaloneD:  "/images/pic/StandaloneD.png",
 StandaloneE:  "/images/pic/StandaloneE.png",
 StandaloneF:  "/images/pic/StandaloneF.png",
 StandaloneG:  "/images/pic/StandaloneG.png",
 StandaloneH:  "/images/pic/StandaloneH.png",
 StandaloneI:  "/images/pic/StandaloneI.png",
 StandaloneJ:  "/images/pic/StandaloneJ.png",
 StandaloneK:  "/images/pic/StandaloneK.png",
 StandaloneL:  "/images/pic/StandaloneL.png",
 StandaloneM:  "/images/pic/StandaloneM.png",
 StandaloneN:  "/images/pic/StandaloneN.png",
 StandaloneO:  "/images/pic/StandaloneO.png",
 StandaloneP:  "/images/pic/StandaloneP.png",
 StandaloneQ:  "/images/pic/StandaloneQ.png",
 StandaloneR:  "/images/pic/StandaloneR.png",
 StandaloneS:  "/images/pic/StandaloneS.png",
 StandaloneT:  "/images/pic/StandaloneT.png",
 StandaloneU:  "/images/pic/StandaloneU.png",
 StandaloneV:  "/images/pic/StandaloneV.png",
 StandaloneW:  "/images/pic/StandaloneW.png",
 StandaloneX:  "/images/pic/StandaloneX.png",
 StandaloneY:  "/images/pic/StandaloneY.png",
 StandaloneZ:  "/images/pic/StandaloneZ.png",
 StandaloneAA: "/images/pic/StandaloneAA.png",
 StandaloneAB: "/images/pic/StandaloneAB.png",
 StandaloneAC: "/images/pic/StandaloneAC.png",
 StandaloneAD: "/images/pic/StandaloneAD.png",
 StandaloneAE: "/images/pic/StandaloneAE.png",
 StandaloneAF: "/images/pic/StandaloneAF.png",
 StandaloneAG: "/images/pic/StandaloneAG.png",
 StandaloneAH: "/images/pic/StandaloneAH.png",
 StandaloneAI: "/images/pic/StandaloneAI.png",
 StandaloneAJ: "/images/pic/StandaloneAJ.png",
 StandaloneAK: "/images/pic/StandaloneAK.png",
 StandaloneAL: "/images/pic/StandaloneAL.png",
 StandaloneAM: "/images/pic/StandaloneAM.png",
 StandaloneAN: "/images/pic/StandaloneAN.png",
 StandaloneAO: "/images/pic/StandaloneAO.png",
 StandaloneAP: "/images/pic/StandaloneAP.png",
 StandaloneAQ: "/images/pic/StandaloneAQ.png",
 StandaloneAR: "/images/pic/StandaloneAR.png",
 StandaloneAS: "/images/pic/StandaloneAS.png",
 StandaloneAT: "/images/pic/StandaloneAT.png",
 StandaloneAU: "/images/pic/StandaloneAU.png",
 StandaloneAV: "/images/pic/StandaloneAV.png",
 StandaloneAW: "/images/pic/StandaloneAW.png",
 StandaloneAX: "/images/pic/StandaloneAX.png",
 StandaloneAY: "/images/pic/StandaloneAY.png",
 StandaloneAZ: "/images/pic/StandaloneAZ.png",
 StandaloneAAA: "/images/pic/StandaloneAAA.png",
 StandaloneAAB: "/images/pic/StandaloneAAB.png",
 StandaloneAAC: "/images/pic/StandaloneAAC.png",
 StandaloneAAD: "/images/pic/StandaloneAAD.png",
 StandaloneAAE: "/images/pic/StandaloneAAE.png",
 StandaloneAAF: "/images/pic/StandaloneAAF.png",
 StandaloneAAG: "/images/pic/StandaloneAAG.png",
 StandaloneAAH: "/images/pic/StandaloneAAH.png",
 StandaloneAAI: "/images/pic/StandaloneAAI.png",
 StandaloneAAJ: "/images/pic/StandaloneAAJ.png",
 StandaloneAAK: "/images/pic/StandaloneAAK.png",
 StandaloneAAL: "/images/pic/StandaloneAAL.png",
 StandaloneAAM: "/images/pic/StandaloneAAM.png",
 StandaloneAAN: "/images/pic/StandaloneAAN.png",
 StandaloneAAO: "/images/pic/StandaloneAAO.png",
 StandaloneAAP: "/images/pic/StandaloneAAP.png",
 StandaloneAAQ: "/images/pic/StandaloneAAQ.png",

}



export default PngIcons
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Icon from "../img/Icon.png"
import PngIcons from './icons/PngIcons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import Manychatnewlogo from "../img/Manychatnewlogo.png";
import SvgIcons from './icons/SvgIcons';





const MainPage = () => {

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    const [data, setData] = useState([
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Brand Setup',
            icon: PngIcons.brandicon,
            link: '/brandsetup'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Sign Up - Stand Alone',
            icon: PngIcons.SignUpStandAlone,
            link: '/standalonesignup'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Sign Up - Cloudwaitress',
            icon: PngIcons.SignUpCloudWaitress,
            link: '/signupcloudwaiters'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Create Reward',
            icon: PngIcons.createReward,
            link: '/createreward'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Business Connector',
            icon: PngIcons.BC,
            link: '/businessconnector'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Setup VIP Club',
            icon: PngIcons.VipClub,
            link: '/vipclub'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Setup Loyality Tiers',
            icon: PngIcons.loyalityTiers,
            link: '/loyalitytiers'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Create Flow - Stand Alone',
            icon: PngIcons.ceateFlow,
            link: '/standalone'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Create Flow - Cloudwaitress',
            icon: PngIcons.CloudWaitressCreateflow,
            link: '/cloudwaiters'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Shared Loyalty - Trigger',
            icon: PngIcons.SharedLoytality,
            link: '/Sharedloyality'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Redemption - Cloudwaitress',
            icon: PngIcons.redemption,
            link: '/redemption'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Integration with Cloudwaitress',
            icon: PngIcons.iwithCloud,
            link: '/createflow'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Integration with ManyChat',
            icon: PngIcons.manyChatt,
            link: '/manychat'
        },
        {
            para: 'Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat.',
            title: 'Integration with GHL',
            icon: PngIcons.ghl,
            link: '/ghlintegration'
        },
    ])

    {/*const handleUserSearch = async e => {
        const filteredCustomer = data.filter(data => data.title.includes(e.target.value))
        setFilter([...filteredCustomer])
    }*/}
    const handleUserSearch = async e => {
        const searchTerm = e.target.value.toLowerCase(); // Convert search term to lowercase
        const filteredData = data.filter(item => item.title.toLowerCase().includes(searchTerm)); // Filter based on search term
        setFilter(filteredData);
        setSearch(searchTerm); // Update search state
    };


    const firstStyle = {
        backgroundImage: 'url(' + PngIcons.gradient + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '560px'
    }
    return (
        <>
            <div className='w-100' id='mycontainer'>
                <div className='w-100' style={firstStyle}>
                    <Navbar collapseOnSelect expand="lg" className="bg-body-transparent">
                        <Container>
                            <Navbar.Brand href="#home"> <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img className='mylogo' src={Manychatnewlogo} />
                            </div></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className='togglebtn' />
                            <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-flex-end '>
                                <Nav>
                                    <Nav.Link href="#deets"> <div className='mylist' >
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className='bgcolor'>
                                        <a href="https://walletly.us/newpage/"  className='btnback'>Back to website</a>
                                            <a href="https://api.walletly.ai"  target="blank" className='btnlogin'>Login</a>
                                        </div>
                                    </div></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>






                    <div className='nav-second-child special-width-mainpage'>
                        <h1 className='Help-Center'>Help Center</h1>
                        <div className='serach-div'>
                            <div >
                                <SvgIcons.Search />
                            </div>
                            <input placeholder='Enter your search term here...' type='text' style={{ width: '100%', height: '100%', border: 'none', outline: 'none', fontSize: '100%' }} onChange={(e) => handleUserSearch(e)} />
                        </div>


                    </div>


                </div>

                <div className='main-all' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '65%' }} >
                    {filter && filter.length ?
                        filter.map((element, index) => {
                            return (
                                <Link  to={element.link}style={{ textDecoration: 'none' }}>
                                    <div className='main-top'>
                                        <img width={'38px'} height={'38px'} src={element.icon} alt='image' />
                                        <h2  style={{ textDecoration: 'none' }} >{element.title}</h2>
                                        <p  style={{ margin: '0px',textDecoration: 'none' }}>{element.para}</p>
                                    </div>
                                </Link>
                            )
                        }) :
                        data.map((element, index) => {
                            return (
                                <Link to={element.link} style={{ textDecoration: 'none' }}>
                                    <div className='main-top'>
                                        <img width={'38px'} height={'38px'} src={element.icon} alt='image' />
                                        <h2 style={{ textDecoration: 'none' }}>{element.title}</h2>
                                        <p style={{ margin: '0px',textDecoration: 'none' }}>{element.para}</p>
                                    </div>
                                </Link>
                            )
                        })
                    }


                </div>
                <div className='footer'>
                    <div className='mainfooter'>
                        <div className='containerfooter'>
                            <p className='walet'>Walletly Inc. 2023 </p>
                            <Link className='term'>Terms Of Service</Link>
                            <Link className='policy'>Privacy Policy</Link>
                        </div>
                       
                        <div><a href="https://www.facebook.com/walletly" target="_blank" rel="noopener noreferrer">
                            <img className='myicon' src={Icon} alt="Facebook Icon" />
                        </a>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};
export default MainPage



// {
    // filter && filter.length ?
    // filter.map((element , index)=>{
    //     return(
    //         <Link to={element.link}>
    //         <div className='main-top'>
    //             <img width={'38px'} height={'38px'} src={element.icon} alt='image' />
    //             <h2>{element.title}</h2>
    //             <p style={{margin : '0px'}}>{element.para}</p>
    //         </div>
    //         </Link>
    //     )
    // }):
// }

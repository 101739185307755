import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Manychatnewlogo from "../img/Manychatnewlogo.png";
import Icon from "../img/Icon.png"
import Vector from "../img/Vector.png"
import PngIcons from './icons/PngIcons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

//import "../../../node_modules/bootstrap/scss/bootstrap.scss"
import divcontainerinner from './icons/PngIcons';
import { Link } from "react-router-dom";





const Redemption = () => {
    const navigation = useNavigate();
    const handelviewall =()=>{
        navigation('/')
    }


    const contentData = [
        {
            title : 'How to set up Basic Automation',
            imageSrc: PngIcons.RedemptionAA,
            paddingTop:'0px',
            width: '100%',
            marginBottom:"0px",

            
            paragraph: "The triggers for Basic Automations by channel can be found in the Automation tab on the left menu."
        },
        {
            imageSrc: PngIcons.RedemptionA,
           width: '70%',
           paddingTop:'0px',
           marginBottom:"0px",
            paragraph: "To create, edit, or attach an existing flow to your selected trigger, click one of the options."
        },
        {
            imageSrc: PngIcons.RedemptionB,
            paddingTop:'0px',
            marginBottom:"0px",
            width: '70%',
            paragraph: "This will take you into Flow Builder where you can edit the attached flow or replace this with an existing one."
        },

        {
            imageSrc: PngIcons.RedemptionC,
            width: '100%',
            paddingTop:'0px',
            marginBottom:"0px",
            paragraph: "If you want to replace a flow with an existing one or disconnect and reconnect a flow for Basic Automations, you'll see a list of your existing flows to choose from or you can click 'Create New Flow' in the top right corner to create a new one:"
        },

        {
            imageSrc: PngIcons.RedemptionD,
            width: '70%',
            paddingTop:'0px',
            marginBottom:"0px",
            paragraph: "If you want to replace a flow with an existing one or disconnect and reconnect a flow for Basic Automations, you'll see a list of your existing flows to choose from or you can click 'Create New Flow' in the top right corner to create a new one:"
        },
        {
            imageSrc: PngIcons.RedemptionE,
            width: '70%',
            paddingTop:'0px',
            marginBottom:"0px",
            paragraph: "If you want to replace a flow with an existing one or disconnect and reconnect a flow for Basic Automations, you'll see a list of your existing flows to choose from or you can click 'Create New Flow' in the top right corner to create a new one:"
        },

        {
            imageSrc: PngIcons.RedemptionF,
            width: '70%',
            paddingTop:'0px',
            marginBottom:"0px",
            paragraph: "If you want to replace a flow with an existing one or disconnect and reconnect a flow for Basic Automations, you'll see a list of your existing flows to choose from or you can click 'Create New Flow' in the top right corner to create a new one:"
        },
        

        
    ];


    const firstStyle = {
        backgroundImage: 'url(' + PngIcons.gradient + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '403px'
    }

    const secondStyle = {
        backgroundImage: 'url(' + PngIcons.divcontainerinner + ')',
        backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        //backgroundPosition: 'center',
        width: '70%',
        height: '167px',



    }

    return (

        <>
            <div className='w-100' id='mycontainer'>
                <div className='w-100' style={firstStyle}>
                        <Navbar collapseOnSelect expand="lg" className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand href="#home"> <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <img className='mylogo' src={Manychatnewlogo} />
                                    

                                </div></Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" className='togglebtn' />
                                <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-flex-end '>

                                    <Nav>
                                        <Nav.Link href="#deets"> <div className='mylist' >
                                        <div style={{display : 'flex' , justifyContent : 'center'}}  className='bgcolor'>
                                             {/*<button className='btnback' >Back to website</button>*/}
                                             <a href="https://walletly.us/newpage/"  className='btnback'>Back to website</a>
                                     
                                            <a href="https://api.walletly.ai"  target="blank" className='btnlogin'>Login</a>
                                            </div>

                                        </div></Nav.Link>

                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>






                    <div className='nav-second-child'>
                        <div className='nav-data-tohide' style={{ display: 'flex', gap: '10px' }}>
                            <Link to='/' className='wraptext'>Walletly Help</Link >
                            <p className='wrap'>› </p>

                           
                            <Link className='wraptext'>Starting your Redemption - Cloudwaitress journey!</Link >

                        </div>

                        <div className='wrapheading'>Basic Automation Triggers</div>
                        <div style={{ marginTop: '30px' }}>
                        <Link className='name'>Adeel Khan </Link>
                            <p className='date'>Modified on: Thu, 27 Aug, 2023 at 6:34 PM</p>
                        </div>

                    </div>


                </div>
                <div className='main-all' >

                {contentData.map((item, index) => (
                <div key={index}>
                   { item.title && <p className='heading'>{item.title}</p>}
                    <p className='paragraphvipclub' style={{ marginTop: item.marginTop || '20px', marginBottom: item.marginBottom ||'30px' }}>{item.paragraph}</p>
                    <img
                        style={{
                            margin: '0 auto',
                            display: 'flex',
                            width: item.width || '100%',
                            paddingTop: item.paddingTop ||'20px',
                            paddingBottom: item.paddingBottom ||'20px',
                        }}
                        src={item.imageSrc}
                        alt={`Step ${index + 1}`}
                    />
                </div>
            ))}
                    
                    {/*<p className='heading'> How to set up Basic Automation

                    </p>
                    <p className='paragraph'>
                    {item.paragraph}
                    </p>

                    <img style={{
                        margin: '0 auto', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px', paddingbottom: '20px'

                    }} src={ManyChat}
                    />
                    <p className='paragraph' style={{ marginRight: '95px' }}>
                        To create, edit, or attach an existing flow to your selected trigger, click one of the options:
                    </p>

                    <img style={{ margin: '0 auto', justifyContent: 'center', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px' }} 
                   src={item.imageSrc}
                   alt={`Step ${index + 1}`}
                    />

                    <p className='paragraph' style={{ fontFamily: 'Helvetica Neue' }}>This will take you into Flow Builder where you can edit the attached flow or replace this with an existing one by
                    </p>

                    <img style={{ margin: '0 auto', justifyContent: 'center', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px' }} src={ManyChatB}
                    />


                    <p className='paragraphA' >If you want to replace a flow with an existing one or disconnect and reconnect a flow for Basic Automations, you'll see
                        a list of your existing flows to choose from or you can click 'Create New Flow' in the top right corner to create a new
                        one:
                    </p>


                    <img style={{ margin: '0 auto', justifyContent: 'center', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px' }} src={ManyChatC}
                    />


                    <p className='paragraph' style={{ fontFamily: 'Helvetica Neue' }}>Please note: If you select an existing flow to attach to your trigger and make any edits, any automation that currently
                    </p>

                    <p className='paragraph' style={{ fontFamily: 'Helvetica Neue' }}>Be sure to check that any toggles for the Basic Automation trigger in your Starting Step are turned on and click
                    </p>

                    <img style={{ margin: '0 auto', justifyContent: 'center', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px' }} src={ManyChatD}
                    />

                    <p className='paragraph' style={{ fontFamily: 'Helvetica Neue' }}>You can also access triggers for Basic Automations by going to your Settings tab in the side menu and selecting the
                    </p>

                <img style={{ margin: '0 auto', justifyContent: 'center', display: 'flex', width: '100%', paddingTop: '20px', paddingbottom: '20px' }} src={ManyChatE} />*/}

                    <div className='mainarticle'>

                        <p className='article'>Related Articles</p>
                        <div className='mylink'>
                            
                        <Link to='/brandsetup' className='link'>Brand Setup</Link>
                            <Link to='/loyalitytiers' className='link'>Setup Loyality Tiers
                            </Link>
                            <Link to='/cloudwaiters' className='link'>Create Flow - Cloudwaitress</Link>
                            <Link to='/businessconnector' className='link'> Business Connector
                            </Link>
                            
                        </div>
                        <button onClick={handelviewall} className='mybtn'>View all</button>
                    </div>


                </div>




                <div className='footer'>

                    <div className='mainfooter'>
                        <div className='containerfooter'>
                            <p className='walet'>Walletly Inc. 2023 </p>
                            <Link to='https://www.walletly.ai/terms/?fbclid=IwAR3Z1iKpTJjPVsotfMBnAriHSF_5OEDCpLzyHl6FrR3TxQedqGDCL02lAfc' className='term'>Terms Of Service</Link>
                            <Link to='https://www.walletly.ai/privacy/?fbclid=IwAR1LMzz30Z9dJjGw_l4Z0A_pGB2-Lol3UmDaXwo7OplW252mO2dCWqMDNrE' className='policy'>Privacy Policy</Link>
                        </div>


               
                        <div><a href="https://www.facebook.com/walletly" target="_blank" rel="noopener noreferrer">
                            <img className='myicon' src={Icon} alt="Facebook Icon" />
                        </a>
                        </div>


                    </div>

                </div>
            </div>
        </>

    );
};
export default Redemption


function Search(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2288 10.7416C17.2288 12.6142 16.5187 14.4102 15.2547 15.7343C13.9908 17.0585 12.2765 17.8023 10.489 17.8023C8.70148 17.8023 6.98719 17.0585 5.72324 15.7343C4.45928 14.4102 3.7492 12.6142 3.7492 10.7416C3.7492 8.86901 4.45928 7.07309 5.72324 5.74895C6.98719 4.42481 8.70148 3.68091 10.489 3.68091C12.2765 3.68091 13.9908 4.42481 15.2547 5.74895C16.5187 7.07309 17.2288 8.86901 17.2288 10.7416ZM16.0003 18.1806C14.1943 19.6495 11.9217 20.3421 9.64527 20.1174C7.36887 19.8927 5.25976 18.7677 3.74733 16.9712C2.23489 15.1747 1.43283 12.842 1.50441 10.4478C1.57599 8.05357 2.51585 5.77795 4.13264 4.08417C5.74943 2.39039 7.92161 1.40578 10.207 1.33079C12.4923 1.2558 14.7191 2.09606 16.4339 3.68052C18.1487 5.26497 19.2226 7.47451 19.4371 9.85931C19.6516 12.2441 18.9904 14.6249 17.5883 16.517L22.1424 21.288C22.2528 21.3957 22.3413 21.5257 22.4027 21.6701C22.4641 21.8145 22.4972 21.9703 22.4998 22.1284C22.5025 22.2864 22.4747 22.4434 22.4182 22.59C22.3617 22.7365 22.2776 22.8696 22.1709 22.9814C22.0642 23.0932 21.9371 23.1813 21.7972 23.2405C21.6573 23.2997 21.5075 23.3288 21.3566 23.326C21.2058 23.3232 21.057 23.2886 20.9192 23.2243C20.7814 23.1599 20.6573 23.0672 20.5545 22.9515L16.0003 18.1806Z" fill="#686C7C"/>
</svg>
    )
}
var SvgIcons = {
    Search
}

export default SvgIcons
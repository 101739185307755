
import './assets/css/global.scss';
//import './assets/css/global.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Helpcenterpage from './Components/Helpcenterpage';
import BrandSetup from './Components/BrandSetup';
import Standalonesignup from './Components/Standalonesignup';
import CreateReward from './Components/CreateReward';
import GHLintegration from './Components/GHLIntegration';
import Businessconnector from './Components/Businessconnector';
import Vipclub from './Components/Vipclub';
import Loyalitytirers from './Components/Loyalitytirers';
import StandAlone from './Components/StandAlone';
import Sharedloyalitytrigger from './Components/Sharedloyalitytrigger';
import Redemption from './Components/Redemption';
import CreateFlow from './Components/CreateFlow';
import Cloudwaiters from './Components/Cloudwaiters';
import SignupCloudwaiters from './Components/SignupCloudwaiters';
import MainPage from './Components/MainPage';



function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route exact path="/"
            element={
              <MainPage />
            }
          />
          <Route exact path="/manychat"
            element={
              <Helpcenterpage />
            }
          />
          <Route exact path="/brandsetup"
            element={
              <BrandSetup />
            }
          />

          <Route exact path="/standalone"
            element={
              <StandAlone />
            }
          />

          <Route exact path="/createreward"
            element={
              <CreateReward />
            }
          />
          <Route exact path="/ghlintegration"
            element={
              <GHLintegration />
            }
          />

          <Route exact path="/businessconnector"
            element={
              <Businessconnector />
            }
          />

          <Route exact path="/vipclub"
            element={
              <Vipclub />
            }
          />


          <Route exact path="/loyalitytiers"
            element={
              <Loyalitytirers />
            }
          />

          <Route exact path="/standalonesignup"
            element={
              <Standalonesignup />
            }
          />

          <Route exact path="/Sharedloyality"
            element={
              <Sharedloyalitytrigger />
            }
          />

          <Route exact path="/redemption"
            element={
              <Redemption />
            }
          />

          <Route exact path="/createflow"
            element={
              <CreateFlow />
            }
          />
          <Route exact path="/cloudwaiters"
            element={
              <Cloudwaiters />
            }
          />

          <Route exact path="/signupcloudwaiters"
            element={
              <SignupCloudwaiters />
            }
          />



        </Routes>





      </BrowserRouter>

    </div>
  );
}

export default App;
